import React, {useState, useEffect} from 'react'
import { Link } from 'react-router-dom';
import { Container, Stack, Grid, Button, Typography, CircularProgress, Box, Alert  } from '@mui/material';
import { CartItem } from '../components/CartItem';
import { useCart } from '../contexts/CartContext';
import api from '../util/Api';
import ResponsiveAppBar from '../components/ResponsiveAppBar';
import Footer from '../components/Footer';

export default function Cart() {
  const { items, updateCartAvailable } = useCart();
  const [alert, setAlert] = useState({show:false,message:null});
  const [loading, setLoading] = useState(false);
  const total = items.reduce((total, item) => total + item.quantity * (item.sales_price ? item.sales_price : item.price), 0);
  const shipping = total >= 59 ? 0.00 : 7.99;
  const needed = Math.max(0, (59 - total));
  
  //Update quantities when cart page loads
  useEffect(() => {
    let controller = new AbortController();
    const config = { 
        'accept': 'application/json',
        'signal': controller.signal,
    };

    const fetchData = async() => {  
      var response = await api.post('/check-cart-quantities', {items:items}, config);
      if(response.status === 200){
        if(response.data?.hasOwnProperty('updatesArr')){
          //Since cart is stored in users browser
          //we need to recheck the stock before allowing order to be placed 
          updateCartAvailable(response.data.updatesArr);
          setLoading(false); 
        }
      }
    }

    fetchData().catch((err) => {setLoading(false);}); 
  }, []);

 function handleCheckout(){
    setLoading(true);
    setAlert({show:false,message:null});
    const config = { 'content-type': 'application/json'};

    const fetchData = async() => {  
        var response = await api.post('/create-checkout-session', {items:items, shipping:shipping}, config);
        console.log("Resp",response);
        if(response.status === 200){
          if(response.data?.hasOwnProperty('url')){
            window.location = response.data.url;
          }else if(response.data?.hasOwnProperty('updatesArr')){
            //Since cart is stored in users browser
            //we need to recheck the stock before allowing order to be placed 
            updateCartAvailable(response.data.updatesArr);
            setAlert({show:true,message:"There was an issue with your request. Please review your order and try again."});
            setLoading(false);
          }else{
            setAlert({show:true, message: response.data});
          }
        }else{
          setAlert({show:true, message:"An issue occured while creating your checkout. Please try again later."});
          console.log(response);
        }
    }

    fetchData().catch((err) => {
      setAlert({show:true,message:"An unexpected error occured. Please try again later."});
      setLoading(false);
    });
  }

  return (<>
      {loading 
      ? <Grid container  justifyContent="center" alignItems="center" sx={{height:'100vh', width:'100vw'}}>
          <Grid item >
            <CircularProgress sx={{margin:'0 auto',  color:"#e9e5d5", justifySelf:'center'}}/>
          </Grid>
        </Grid>
      : <>
        <ResponsiveAppBar />     
        <Stack className="contentWrapperPadded">  
              <Stack sx={{paddingBottom: '10px', borderBottom:'1px solid #f0f0f0'}}>
                <Typography sx={{fontWeight:'bold', fontSize:'1.5em'}}>My Cart</Typography>
              </Stack>
              {alert.show &&
                <Alert severity="error">{alert.message}</Alert>
              }

              <Stack sx={{flexGrow:1, padding:'20px 0', width:'100%'}}>
              
                    {
                        items.length > 0
                        ? items.map((item) => {
                            return(
                                <CartItem key={item.id} {...item}/>
                            )
                        })
                        :<Stack sx={{textAlign:'left',}}>Your cart is empty.</Stack>
                    }  
              </Stack>
                {items.length > 0 &&
                  <Stack direction = "horizontal" sx={ {justifyContent:'flex-end', alignItems:'flex-end'}} >
                    <Grid container sx={{width:{xs:'100%', md:'35%', lg:'25%'}, justifyContent:'flex-end'}}>
                      <Grid item xs={6} sx={{flexGrow:1}}>Subtotal:</Grid>
                      <Grid item xs={6} sx={{textAlign:'right'}}>${total.toFixed(2)}</Grid>
                      <Grid item xs={6} sx={{flexGrow:1,}}>Tax:</Grid>
                      <Grid item xs={6} sx={{textAlign:'right'}}>T.B.D.</Grid>
                      <Grid item xs={6} sx={{flexGrow:1, paddingBottom:'10px'}}>Flat Shipping Rate:</Grid>
                      <Grid item xs={6} sx={{textAlign:'right', paddingBottom:'10px'}}>${shipping.toFixed(2)}</Grid>
                      
                      {needed && <Grid item xs={12} sx={{textAlign:'center', padding:'8px 0px', fontStyle:"italic", color:"#a6b396"}}>
                        <Typography variant="body2">Spend ${needed.toFixed(2)} more for free shipping.</Typography>
                      </Grid>}
                      
                      <Grid item xs={6} sx={{textAlign:'left', fontWeight:'bold', borderTop:'1px solid #f0f0f0', padding:'8px 0px'}}>Total:</Grid>
                      <Grid item xs={6} sx={{textAlign:'right',fontWeight:'bold',borderTop:'1px solid #f0f0f0',  padding:'8px 0px'}}>${(total + shipping).toFixed(2)}</Grid>
                      <Grid item xs={12} sx={{ marginTop:'10px', textAlign:{xs:'center', sm:'right'}}}>          
                        <Button onClick={()=>handleCheckout()} className='sage' sx={{width:{xs:'100% !important', sm:'auto'}}}>Proceed to checkout</Button>   
                      </Grid>
                    </Grid>
                  </Stack>
                }
          </Stack>     
          <Footer />
        </>
    }
    </>
  );
}

